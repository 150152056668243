<template>
  <div :class="gameSideMenuClass">
    <div v-if="isPending" class="csn-gsm-loader"><Loader /></div>
    <div v-if="rendersGameData" class="csn-gsm-upper-container">
      <div class="csn-gsm-header">
        <span class="csn-gsm-game-name">{{ game.name }}</span>
        <div class="csn-gsm-close-button" @click="handleClose">
          <CloseIcon />
        </div>
      </div>

      <div class="csn-gsm-game-icon">
        <img v-if="game.image" :src="game.image" alt="game-image" />
        <img
          v-else-if="isCasinoPlatin"
          src="@/assets/images/default_gamebox_horizontal.png"
        />
        <img
          v-else
          src="https://cdn-casino.blackpage.pl/images/logo/casino-five.svg"
        />
      </div>

      <div class="csn-gsm-game-data">
        <div class="csn-gsm-game-rating">
          <Rating
            :value="rating"
            :isEditable="isLogged"
            :isRatingPending="isRatingPending"
            :changeValue="changeRatingValue"
          />
        </div>
        <div class="csn-gsm-vendor-logo">
          <img :src="game.vendorLogo" alt="vendor-logo" />
        </div>
        <div v-if="isLogged" class="csn-gsm-favour">
          <FavoriteIcon
            :isButton="false"
            :isFavorite="isFavorite"
            :isPending="IS_GAME_FAV_STATUS_PENDING"
            @click="toggleFavourite"
          />
        </div>
      </div>
    </div>
    <div v-if="rendersGameData" class="csn-gsm-lower-container">
      <button
        v-if="hasPlayButton"
        class="btn casino-btn csn-gsm-play-btn"
        @click="handlePlayClick"
      >
        {{ t('play_now') }}
      </button>
      <button
        v-if="hasDemoButton"
        class="btn casino-btn csn-gsm-demo-btn"
        @click="handleDemoClick"
      >
        {{ t('try_me') }}
      </button>
      <div v-if="isLogged" class="csn-gsm-line"></div>
      <div v-if="isLogged" class="csn-gsm-balance-container">
        <div class="csn-gsm-balance">
          <span class="csn-gsm-balance-label">{{ t('balance') }}</span>
          <span class="csn-gsm-balance-value">{{ balance }}</span>
        </div>

        <Route
          :to="{ name: depositRoute }"
          @click.native="handleClose"
          class="btn casino-btn csn-gsm-deposit-btn"
        >
          {{ t('deposit') }}
        </Route>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { GameApi, AuthApi } from '@/api'
import {
  GAME_SIDE_MENU,
  Module,
  SIDE_MENU_GAME,
  SET_SIDE_MENU_GAME,
  EMPTY_STR,
  ResponseState,
  IS_LOGGED,
  SET_GAME_PLAY_DICTIONARY,
  GAME_PLAY_DICTIONARY,
  UPDATE_GAME_FAVOURITE_STATUS,
  IS_GAME_FAV_STATUS_PENDING,
  IS_GAME_FAVORITE,
  RouteName,
  CASINO_INFO,
  Digit,
  EMPTY_ARRAY,
  CASINO_PLATIN,
} from '@/constants'
import { dest, navigateTo } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

const CSN_GAME_SIDE_MENU = 'csn-gsm'
const CSN_GAME_SIDE_MENU_DISPLAYED = `${CSN_GAME_SIDE_MENU}-displayed`

export default {
  name: GAME_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    Rating: () => import('@/components/Rating'),
    FavoriteIcon: () => import('@/components/FavoriteIcon'),
    Route: () => import('@/components/Route'),
    Loader: () => import('@/components/Loader'),
  },
  data: () => ({
    game: null,
    rating: Digit.NOUGHT,
    isRatingPending: false,
    isPending: false,
  }),
  computed: {
    ...mapState(Module.MAIN, [SIDE_MENU_GAME]),
    ...mapState(Module.GAME_PLAY, [
      GAME_PLAY_DICTIONARY,
      IS_GAME_FAV_STATUS_PENDING,
    ]),
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    ...mapGetters({
      getFavoriteStatus: dest([Module.GAME_PLAY, IS_GAME_FAVORITE]),
    }),
    rendersGameData() {
      return !this.isPending && this.game
    },
    id() {
      return this.SIDE_MENU_GAME?.id
    },
    slug() {
      return this.SIDE_MENU_GAME?.slug
    },
    gameSideMenuClass() {
      return [
        CSN_GAME_SIDE_MENU,
        this.slug ? CSN_GAME_SIDE_MENU_DISPLAYED : EMPTY_STR,
      ]
    },
    isFavorite() {
      return this.getFavoriteStatus(this.slug)
    },
    hasPlayButton() {
      return this.game?.buttons?.play
    },
    hasDemoButton() {
      return this.game?.buttons?.demo
    },
    t() {
      return this.$createComponentTranslator(GAME_SIDE_MENU)
    },
    balance() {
      return CasinoInfo.getAccountBalance(this.CASINO_INFO)
    },
    depositRoute: () => RouteName.DEPOSIT,
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  watch: {
    slug: {
      immediate: true,
      handler(slug) {
        slug ? this.getGameData(slug) : (this.game = null)
      },
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [SET_SIDE_MENU_GAME]),
    ...mapActions(Module.GAME_PLAY, [
      SET_GAME_PLAY_DICTIONARY,
      UPDATE_GAME_FAVOURITE_STATUS,
    ]),
    handleClose() {
      this.SET_SIDE_MENU_GAME(null)
    },
    async getGameData() {
      try {
        this.isPending = true

        const response = await Promise.all([
          GameApi.getGameInfo({ urlSlug: this.slug }),
          GameApi.getGameRating({ id: this.id }),
          ...(this.isLogged
            ? [this.SET_GAME_PLAY_DICTIONARY({ urlSlug: this.slug })]
            : EMPTY_ARRAY),
        ])

        if (response.every((i) => i.state === ResponseState.OK)) {
          this.game = response[Digit.NOUGHT].data.game
          this.rating = response[Digit.ONE].data.rating
        }
      } catch (e) {
        console.dir(e)
      }

      this.isPending = false
    },
    toggleFavourite() {
      this.UPDATE_GAME_FAVOURITE_STATUS({
        id: this.game.id,
        urlSlug: this.game.urlSlug,
        isFavorite: !this.isFavorite,
      })
    },
    handlePlayClick() {
      this.handleClose()
      navigateTo({
        name: RouteName.GAME_PLAY,
        params: { slug: this.slug },
      })
    },
    handleDemoClick() {
      this.handleClose()
      navigateTo({
        name: RouteName.GAME_DEMO,
        params: { slug: this.slug },
      })
    },
    async changeRatingValue(value) {
      this.isRatingPending = true
      const { state } = await AuthApi.postGameRating({
        form: {
          rating: String(value),
          game: String(this.game.id),
        },
      })

      if (state === ResponseState.OK) {
        this.rating = value
      }
      this.isRatingPending = false
    },
  },
}
</script>
